@font-face {
font-family: '__ModeratTrialFontModule_6116da';
src: url(/_next/static/media/9abd822fb390a4e0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__ModeratTrialFontModule_6116da';
src: url(/_next/static/media/7ae97fb588ab2383-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__ModeratTrialFontModule_6116da';
src: url(/_next/static/media/844e98a3a96a8a7a-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__ModeratTrialFontModule_6116da';
src: url(/_next/static/media/ceee7e55ce2af866-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ModeratTrialFontModule_6116da';
src: url(/_next/static/media/bc4235cb4358eb79-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__ModeratTrialFontModule_6116da';
src: url(/_next/static/media/c28ba2feed8f6716-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__ModeratTrialFontModule_Fallback_6116da';src: local("Arial");ascent-override: 97.18%;descent-override: 19.44%;line-gap-override: 0.00%;size-adjust: 102.90%
}.__className_6116da {font-family: '__ModeratTrialFontModule_6116da', '__ModeratTrialFontModule_Fallback_6116da'
}.__variable_6116da {--Moderat-Trial-font: '__ModeratTrialFontModule_6116da', '__ModeratTrialFontModule_Fallback_6116da'
}

